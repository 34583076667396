import React from "react"
import { graphql } from "gatsby" // to query for image data


import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const SuccessPage = ({ data }) => (
    <Layout>
        <SEO title="Signup Success" />
        <Hero
            image={data.heroImage}
            title="Success!"
            noSignup
        >
            <p className="leading-normal text-xl mb-8">
                We have received you registration! You will receive an email when your trial is approved.
            </p>
            <p className="leading-normal text-xl mb-8">
                Contact us at <a href="mailto:support@cargo-planner.com">support@cargo-planner.com</a>{" "}
                or through the chat if you have any questions</p>
        </Hero>
    </Layout>
)

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "pallets/pretty-pallet.png" }) {
      base
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
export default SuccessPage
